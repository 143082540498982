<template>
    <v-app>
        <div class="d-flex justify-space-between align-center">
            <div class="d-flex justify-center align-center mb-4" style="
            width: 96px;
            height: 32px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            border-radius: 4px;
            word-break:normal;
            color: #F89035;
            background: #FFE8D3;">
                Banners
            </div>
        </div>
        <div style="width: 100%">
            <div
                class="mb-3"
                style="width: 96px;
                height: 19px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #333333;
                flex: none;
                order: 0;
                flex-grow: 0;"
            >
                Banner Type
            </div>
            <div
            style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            border-radius: 8px;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;"
            >
                <v-autocomplete 
                :disabled='editFlag'
                outlined
                background-color="#F4F6FA"
                v-model="bannerType"
                :items="bannerTypes"
                required
                />
            </div>
        </div>
        
        <div class="justify-space-between;width:1403px; height:654px">
            <div class="mtba">
                <v-text-field 
                    :rules="!editFlag ? [noSameBannerName(bannerName)]: []"
                    v-model="bannerName" 
                    label="Banner Name" 
                    required 
                    outlined 
                    background-color="#F4F6FA"
                />
            </div>
            <div v-for="(banner, index) of bannerData" :key="index" class="mb-2">
                <div class="showCollasibleBanner d-flex flex-row" v-if="collapsibleBannerFlag">
                    <div class="collapsibleBannerNameClass">
                        Banner {{ String(index + 1) }}
                    </div>
                    <div class="">
                        <span class="collapsibleBannerKeyClass">
                            Start Date
                        </span>
                        <span class="collapsibleBannerDetailsClass">
                            {{ banner.bannerStartDate  }}
                        </span>
                    </div>
                    <div class="">
                        <span class="collapsibleBannerKeyClass">
                            End Date
                        </span>
                        <span class="collapsibleBannerDetailsClass">
                            {{ banner.bannerExpireDate  }}
                        </span>
                    </div>
                    <div class="d-flex ml-2">
                        <img class="mr-2" :src="editIcon" @click="openEditCarousalBanner(banner)"/>
                        <img class="mr-2" :src="deleteIcon" @click="deleteCarousalBanner(banner)"/>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row my-2" style="width:100%; justify-content: space-between;">
                <div style="width:49%; justify-content: space-evenly;" :class="previewDesktopURL ? 'height200' : 'height80'">
                    <div class="d-flex"> 
                        <div class="mtba" style="width:10%;">
                            <img :src="desktopImage" />
                        </div>
                        <div class="mtba" style="width:65%;text-align:left">
                            <div>
                                Web Banner
                            </div>
                            <div>
                                {{ desktopBannerResolution }}
                            </div>
                        </div>
                        <div v-if="showDesktopUploadButton" class="uploadBannerButtonStyle mtba" style="width:20%; height: fit-content;" @click="onDesktopBannerClick">
                            <img class="mtba" :src="uploadBannerIcon" />
                            <p class="mtba">Upload</p> 
                            <input
                                ref="uploader"
                                class="d-none"
                                type="file"
                                accept="image/*"
                                @change="onDesktopBannerChanged"
                            >
                        </div>
                        <div v-else class="removeButtonStyle mtba" style="width:20%; height: fit-content;" @click="removeDesktopBanner">
                            <p class="mtba">Remove</p> 
                            <input
                                ref="uploader"
                                class="d-none"
                                type="file"
                                accept="image/*"
                                @change="onDesktopBannerChanged"
                            >
                        </div>
                    </div>
                    <div v-if="previewDesktopURL" style="width: 100%; height: 124.37px;">
                        <div style="width: 100%; text-align:center;background: #F4F6FA;">
                            <img class="mtb20" style="width: 150px; height: 84.37px; " :src="previewDesktopURL" alt="Preview Image" />
                        </div>
                    </div>
                </div>
                <div style="width:49%; justify-content: space-evenly;" :class="previewMobileURL ? 'height200' : 'height80'">
                    <div class="d-flex"> 
                        <div class="mtba" style="width:10%;">
                            <img :src="mobileImage" />
                        </div>
                        <div class="mtba" style="width:65%;text-align:left">
                            <div>
                                Mobile Banner
                            </div>
                            <div>
                                {{ mobileBannerResolution }}
                            </div>
                        </div>
                        <div v-if="showMobileUploadButton" class="uploadBannerButtonStyle mtba" style="width:20%;height: fit-content;" @click="onMobileButtonClick">
                            <img class="mtba" :src="uploadBannerIcon" />
                            <p class="mtba">Upload</p>
                            <input
                                ref="mobileBannerUploader"
                                class="d-none"
                                type="file"
                                accept="image/*"
                                @change="onMobileBannerChanged"
                            >
                        </div>
                        <div v-else class="removeButtonStyle mtba" style="width:20%; height: fit-content;" @click="removeMobileBanner">
                            <p class="mtba">Remove</p> 
                            <input
                                ref="mobileBannerUploader"
                                class="d-none"
                                type="file"
                                accept="image/*"
                                @change="onMobileBannerChanged"
                            >
                        </div>
                    </div>
                    <div v-if="previewMobileURL" style="width: 100%; height: 124.37px;">
                        <div style="width: 100%; text-align:center;background: #F4F6FA;">
                            <img class="mtb20" style="width: 150px; height: 84.37px; " :src="previewMobileURL" alt="Preview Image" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row mt-6" style="width:100%; justify-content: space-between;">
                <div class="d-flex flex-column mtba" style="width:49%; justify-content: space-evenly;">
                    <div class="" style="text-align: left;">
                        <span> 
                            Start Date 
                        </span>
                    </div>
                    <div class=""> 
                        <v-menu min-width="auto" v-model="bannerStartDateMenu" ref="bannerStartDateMenu" offset-y transition="scale-transition" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="bannerStartDate" class="rounded-lg" style="border-color: #E0E0E0;" 
                                    outlined
                                    placeholder="Start Date"
                                    v-bind="attrs"
                                    v-on="on"
                                    required
                                    background-color="#F4F6FA"
                                />
                            </template>
                            
                            <v-date-picker :rules="[startDateRules]" v-model="bannerStartDate" :min="minDate" required @change="bannerExpireDateMenu = false" />
                        </v-menu>
                    </div>
                </div>
                <div class="d-flex flex-column mtba" style="width:49%; justify-content: space-evenly;">
                    <div class="" style="text-align: left;">
                        <span> 
                            Expire Date 
                        </span>
                    </div>
                    <div class=""> 
                        <v-menu min-width="auto" v-model="bannerExpireDateMenu" ref="bannerExpireDateMenu" offset-y transition="scale-transition" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="bannerExpireDate" required class="rounded-lg" style="border-color: #E0E0E0;"
                                    outlined
                                    placeholder="Expire Date"
                                    v-bind="attrs"
                                    v-on="on"
                                    background-color="#F4F6FA"
                                />
                            </template>
                            <v-date-picker required v-model="bannerExpireDate" :min="expiryMinDate" @change="bannerExpireDateMenu = false" />
                        </v-menu>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                <div class="d-flex flex-column" style="width:49%; justify-content: space-evenly;">
                    <div class="" style="text-align: left;">
                        <span>Jump Link</span>
                    </div>
                    <div class=""> 
                        <v-autocomplete 
                        outlined
                        background-color="#F4F6FA"
                        v-model="jumpLink"
                        :items="routeNames"
                        required
                        />
                    </div>
                </div>
                <div class="d-flex flex-column" style="width:49%; justify-content: space-evenly;" v-if="false">
                    <div class="mtba" style="text-align: left;">
                        <span>Custom URL</span>
                    </div>
                    <div class="mtba"> 
                        <v-autocomplete
                        outlined
                        background-color="#F4F6FA"
                        v-model='customURL' />
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column" style="width:100%; justify-content: space-between;">
                <div class="mtba" style="text-align: left;"> 
                    <span>Banner Priority</span>
                </div>
                <div class="mtba"> 
                    <v-autocomplete
                    outlined
                    background-color="#F4F6FA" 
                    v-model="bannerPriority" 
                    :items="bannerPriorityItems"
                    required
                    />
                </div>
            </div>
            <div class="d-flex flex-row my-2" style="width:100%; justify-content: space-between;"
            :class="activeStatus ? 'borderBlue': 'discardBorder'">
                <div 
                class="mtba mx-3"
                :class="activeStatus ? 'colorBlue' : 'discardButtonFont'" 
                style="font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        height: 19px;
                        flex: none;
                        order: 0;
                        flex-grow: 0;"> 
                    <span>Active Status: </span>
                    <span>{{ activeStatus ? 'Active' : 'InActive' }}</span>
                </div>
                <div class="mtba"> 
                    <v-switch v-model="activeStatus"  required />
                </div>
            </div>
        </div>
        <div class="mt-2" v-if="bannerType ==='Carousal' && allFieldsFilled">
            <div 
            class="d-flex justify-space-between align-center"
            style="
            padding: 10px 16px;
            gap: 10px;
            width: 152px;
            height: 36px;
            background: #48ACEF;
            border-radius: 4px;
            cursor: pointer;
            flex: none;
            order: 1;
            flex-grow: 0;"
            :class="{'enabledDiv': allFieldsFilled}"
            @click="addMultipleBanner()"
            >
                <img :src="multipleAdds"/>
                <span class="d-flex justify-space-between align-center" style="color: #FFFFFF">
                    Add Banner
                </span>
            </div>
        </div>
        <div> 
            <div class="d-flex flex-row mtba" style="width:100%; height: 48px; justify-content: space-between;">
            </div>
            <div class="d-flex flex-row mtba" style="width:100%; height: 48px; justify-content: end;">
                <v-btn class="mx-3 saveDiscardButton discardButtonFont" color="#FFFFFF" @click="discardAddBannerDialog()">Discard</v-btn>
                <v-btn :disabled="isDisabled"  class="mx-3 saveDiscardButton saveButtonFont" color="#219653" @click="saveBannerDetails()">Save</v-btn>
            </div>
        </div>
        <div v-if="displayDiscardDialog">
            <DiscardDialog
                :displayDiscardDialogProps="displayDiscardDialog"
                @closeDiscardDialog="closeDiscardDialog"
            ></DiscardDialog>
        </div>
    </v-app>
</template>

<script>
import { axios_auth_instance_epharmacy_admin } from '../utils/axios_utils';
import router from '@/router';
import DiscardDialog from '../components/DiscardConfirmationDialog.vue';

export default {
    name: 'UploadBannerComponent',
    props:['editBannerDetails', 'editBannerFlagProps', 'orderNumber', 'promoBannerNameList', 'pageType'],
    components: {
        'DiscardDialog': DiscardDialog,
    },
    computed: {
        allFieldsFilled() {
            return this.mobileBannerImageURL && this.desktopBannerImageURL && this.bannerStartDate && this.bannerExpireDate
            && this.bannerName && this.bannerPriority && this.jumpLink && this.bannerType;
        },
        isDisabled() {
            return !(this.mobileBannerImageURL && this.desktopBannerImageURL && this.bannerStartDate && this.bannerExpireDate
            && this.bannerName && this.bannerPriority && this.jumpLink && this.bannerType);
        },
		noSameBannerName(value) {
			return (value) => {
                let returnFlag =  false;
                if(this.promoBannerNameList && this.promoBannerNameList.includes(value)) {
                    returnFlag = true;
                }
                if(returnFlag) return "Banner Segment Name Already Exists";
                if (!value.trim()) return "Give a valid hero banner name";
                else return true;
            }
		},
        startDateRules() {
            return [ 
                (value) => {
                    if(!value) {
                        return 'Date is required' // Basic validation to check if the date is not empty
                    }
                },
                (value) => {
                // Custom validation to check if the selected date is not in the past
                const currentDate = new Date();
                const selectedDate = new Date(value);
                if (selectedDate < currentDate) {
                    return 'Selected date must be in the future';
                }
                return true;
                },
            ];
        },
	},
    data() {
        return {
            displayDiscardDialog: false,
            minDate: new Date().toISOString().substr(0, 10), // Set the minimum date to today's date
            expiryMinDate: new Date().toISOString().substr(0, 10),
            editIcon: 'editIcon.svg',
            deleteIcon: 'deleteIcon.svg',
            collapsibleBannerFlag: false,
			desktopBannerImageName: '',
            desktopBannerImageURL: '',
			mobileBannerImageName: '',
            mobileBannerImageURL: '',
            bannerTypes: ['Carousal', 'Single'],
            bannerType: '',
            addBannerDialog: true,
            bannerName: '',
            displayBanner: '',
            editFlag: false,
            selectedFile: null,
            showMobileUploadButton: true,
            showDesktopUploadButton: true,
            previewDesktopURL: null,
            previewMobileURL: null,
            desktopImage: 'desktopImage.svg',
            mobileImage: 'mobileImage.svg',
            uploadBannerIcon: 'uploadBannerIcon.svg',
            cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
            mobileBannerResolution: 'Ratio: 16:1 | Resolution: Width - 335px, Height - 188px',
            desktopBannerResolution: 'Ratio: 4:1 | Resolution: Width - 1520px, Height - 380px',
            webImageSizeWidth: 1520,
            webImageSizeHeight: 380,
            mobileImageSizeWidth: 335,
            mobileImageSizeHeight: 188,
            bannerStartDateMenu: false,
			bannerExpireDateMenu: false,
			bannerStartDate: '',
			bannerExpireDate: '',
			jumpLink: '',
            customURL:'',
            routeNames: [],
            bannerPriority: '',
            bannerPriorityItems: [1,2,3,4,5,6,7,8,9],
            activeStatus: false,
            bannerList: [],
            multipleAdds: 'multipleAdds.svg',
            callPromotionalBannerAPI: false,
            bannerData: [],
        };
    },
    watch: {
        bannerExpireDate: function(value) {
            if(!(this.bannerExpireDate)) {
                return "Enter a Valid Expiry Date";
            } else {
                return true;
            }
        },
        bannerStartDate: function(value) {
            this.bannerExpireDate = null;
			this.expiryMinDate = value;
		},
        previewDesktopURL: function(e) {
            if(this.$data.previewDesktopURL) {
                this.uploadIntoS3(this.$data.previewDesktopURL, this.desktopBannerImageName, 'DESKTOP');
            } else {
                this.$data.previewDesktopURL = null;
            }
        },
        previewMobileURL: function(e) {
            if(this.$data.previewMobileURL) {
                this.uploadIntoS3(this.$data.previewMobileURL, this.mobileBannerImageName, 'MOBILE');
            } else {
                this.$data.previewMobileURL = null;
            }
        },
    },
    methods: {
        closeDiscardDialog(value) {
            if(value.value) {
                this.$emit("discardAddBannerDialog", this.addBannerDialog);
            } else {
                this.displayDiscardDialog = false;
            }
        },
        async removeDesktopBanner() {
            this.$data.previewDesktopURL = null;
            let removeBannerData = {
                key: this.removeDesktopBannerName
            }
            // let removeUploadedBannerFromS3ResData = await axios.post("/epharmacy/admin/removeUploadedBannerFromS3", removeBannerData);
            this.showDesktopUploadButton = true;
        },
        async removeMobileBanner() {
            this.$data.previewMobileURL = null;
            this.showMobileUploadButton = true;
        },
        async uploadIntoS3(url, name, displayDevice) {
            let fileName = `${String(Date.now())}_${displayDevice}_${name}`;
            let s3UploadData = { 
                base64Str: url,
                fileType: displayDevice === 'DESKTOP' ? this.contentDesktopType : this.contentMobileType,
                fileName: fileName
            }
            if(s3UploadData.fileType) {
                try {
                    let s3UploadResponse = await axios_auth_instance_epharmacy_admin.post("/admin/bannerUploadIntoS3", s3UploadData);
                    if(s3UploadResponse && s3UploadResponse.data && s3UploadResponse.data.data) {
                        if(displayDevice === 'DESKTOP') {
                            this.desktopBannerImageName = fileName;
                            this.desktopBannerImageURL = this.cloudFront + fileName;
                            this.showDesktopUploadButton = false;
                        } 
                        if(displayDevice === 'MOBILE') {
                            this.mobileBannerImageName = fileName;
                            this.mobileBannerImageURL = this.cloudFront + fileName;
                            this.showMobileUploadButton = false;
                        }
                    } else {
                        // this.$cookies.remove('token');
                        // this.$router.push({
                        //     name: "AdminLogin",
                        // });
                    }
                } catch (error) {
                    if(error && error.response && error.response.status == 401) {
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$cookies.remove('token');
                        this.$router.push({
                            name: "AdminLogin",
                        });
                    }
                }
            }
        },
        async saveBannerDetails() {
            if(this.bannerType === 'Single') {
                this.bannerList.push({
                    bannerID: 1,
                    bannerStartDate: this.bannerStartDate,
                    bannerExpireDate: this.bannerExpireDate,
                    jumpLink: this.jumpLink,
                    bannerPriority: this.bannerPriority,
                    activeStatus: this.activeStatus,
                    desktopBannerImageURL: this.desktopBannerImageURL,
                    desktopBannerImageName: this.desktopBannerImageName,
                    mobileBannerImageName: this.mobileBannerImageName,
                    mobileBannerImageURL: this.mobileBannerImageURL,
                });
                this.callPromotionalBannerAPI = true;
            }
            if(this.bannerType === 'Carousal') {
                this.callPromotionalBannerAPI = false;
                if(this.bannerData.length <= 0) {
                    if(!(this.bannerName === undefined || this.bannerName === '')) {
                        this.bannerList.push({
                            bannerID: 1,
                            bannerStartDate: this.bannerStartDate,
                            bannerExpireDate: this.bannerExpireDate,
                            jumpLink: this.jumpLink,
                            bannerPriority: this.bannerPriority,
                            activeStatus: this.activeStatus,
                            desktopBannerImageURL: this.desktopBannerImageURL,
                            desktopBannerImageName: this.desktopBannerImageName,
                            mobileBannerImageURL: this.mobileBannerImageURL,
                            mobileBannerImageName: this.mobileBannerImageName,         
                        });
                        this.callPromotionalBannerAPI = true;
                    } else {

                    }
                } else {
                    if(!((this.bannerStartDate === undefined || this.bannerStartDate === '') 
                    && (this.bannerExpireDate === undefined || this.bannerExpireDate === '') 
                    )) {
                        for (let bannerDatum of this.bannerData) {
                            this.bannerList.push({
                                bannerID: bannerDatum.bannerID,
                                bannerStartDate: bannerDatum.bannerStartDate,
                                bannerExpireDate: bannerDatum.bannerExpireDate,
                                jumpLink: bannerDatum.jumpLink,
                                bannerPriority: bannerDatum.bannerPriority,
                                activeStatus: bannerDatum.activeStatus,
                                desktopBannerImageURL: bannerDatum.desktopBannerImageURL,
                                desktopBannerImageName: bannerDatum.desktopBannerImageName,
                                mobileBannerImageURL: bannerDatum.mobileBannerImageURL,
                                mobileBannerImageName: bannerDatum.mobileBannerImageName,
                            });
                        }
                        this.bannerList.push({
                            bannerID: this.bannerData && this.bannerData.length > 0  ? this.bannerData.length + 1 : 1,
                            bannerStartDate: this.bannerStartDate,
                            bannerExpireDate: this.bannerExpireDate,
                            jumpLink: this.jumpLink,
                            bannerPriority: this.bannerPriority,
                            activeStatus: this.activeStatus,
                            desktopBannerImageURL: this.desktopBannerImageURL,
                            desktopBannerImageName: this.desktopBannerImageName,
                            mobileBannerImageURL: this.mobileBannerImageURL,
                            mobileBannerImageName: this.mobileBannerImageName,         
                        });
                        this.callPromotionalBannerAPI = true;
                    }
                    this.callPromotionalBannerAPI = true;
                }
            }
            if(this.callPromotionalBannerAPI) {
                var promoBannerDataToBeSaved = {
                    bannerName: this.bannerName, // == 'Single' ? this.bannerName : this.bannerList[0].bannerName,
                    bannerType: this.bannerType,
                    bannerList: this.bannerList,
                    segmentOrder: this.$props.orderNumber,
                    pageType: this.pageType,
                }
                try {
                    if(this.editFlag) {
                        promoBannerDataToBeSaved['shortID'] = this.shortID;
                        let updateBannerResponse = await axios_auth_instance_epharmacy_admin.post("/admin/updateBanner", promoBannerDataToBeSaved);
                        if(updateBannerResponse) {
                            this.$emit('updateBannerResponseEmit', true);
                        } else {
                            // this.$cookies.remove('token');
                            // this.$router.push({
                            //     name: "AdminLogin",
                            // });
                        }
                    } else {
                        let updateBannerResponse = await axios_auth_instance_epharmacy_admin.post("/admin/addPromotionalBanner", promoBannerDataToBeSaved);
                        if(updateBannerResponse) {
                            this.$emit('updateBannerResponseEmit', true);
                        } else {
                            // this.$cookies.remove('token');
                            // this.$router.push({
                            //     name: "AdminLogin",
                            // });
                        }
                    }
                } catch (error) {
                    if(error && error.response && error.response.status == 401) {
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$cookies.remove('token');
                        this.$router.push({
                            name: "AdminLogin",
                        });
                    }
                }
                this.isLoading = false;
            } 
        },
        addMultipleBanner() {
            let bannerID =  this.bannerData && this.bannerData.length > 0 ? this.bannerData.length : 0;
            this.bannerData.push({
                bannerID: bannerID + 1,
                bannerStartDate: this.bannerStartDate,
                bannerExpireDate: this.bannerExpireDate,
                jumpLink: this.jumpLink,
                bannerPriority: this.bannerPriority,
                activeStatus: this.activeStatus,
                desktopBannerImageURL: this.desktopBannerImageURL,
                desktopBannerImageName: this.desktopBannerImageName,
                mobileBannerImageURL: this.mobileBannerImageURL,
                mobileBannerImageName: this.mobileBannerImageName,
            });
            this.clearBannerDetails();
            this.collapsibleBannerFlag = true;
        },
        clearBannerDetails() {
            this.bannerStartDate = '';
            this.bannerExpireDate = '';
            this.jumpLink = '';
            this.bannerPriority = '';
            this.activeStatus = false;
            this.desktopBannerImageURL = '';
            this.desktopBannerImageName = '';
            this.mobileBannerImageName = '';
            this.previewDesktopURL = null;
            this.mobileBannerImageURL = '';
            this.previewMobileURL = null;
            this.showMobileUploadButton = true;
            this.showDesktopUploadButton = true;
        },
        onDesktopBannerClick() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
                this.isSelecting = false
            });
            this.$refs.uploader.click();
        },
        onMobileButtonClick() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
                this.isSelecting = false
            });
            this.$refs.mobileBannerUploader.click();
        },
        openEditCarousalBanner(value) {
            this.activeStatus = value.activeStatus; // === 'ACTIVE' ? true : false;
            this.bannerStartDate = value.bannerStartDate;
            this.bannerExpireDate = value.bannerExpireDate;
            this.bannerPriority = value.bannerPriority;
            this.jumpLink = value.jumpLink;
            this.desktopBannerImageName = value.desktopBannerImageName;
            this.mobileBannerImageName = value.mobileBannerImageName;
            this.desktopBannerImageURL = value.desktopBannerImageURL;
            this.mobileBannerImageURL = value.mobileBannerImageURL;
            this.$data.previewDesktopURL = value.desktopBannerImageURL;
            this.$data.previewMobileURL = value.mobileBannerImageURL;
            this.showDesktopUploadButton = false;
            this.showMobileUploadButton = false;
            // ID of the object to remove
            let idToRemove = value.bannerID; 
            let index = this.bannerData.findIndex(obj => obj.bannerID === idToRemove);
            if (index !== -1) {
                this.bannerData.splice(index, 1);
            }
        },
        deleteCarousalBanner(value) {
        },
        onDesktopBannerChanged(e) {
            this.selectedFile = e.target.files[0];
            const file = e.target.files[0];
            this.desktopBannerImageName = file.name;
            this.removeDesktopBannerName = file.name;
            this.contentDesktopType = file.type;
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const image = new Image();
                    image.src = event.target.result;

                    image.onload = () => {
                        // Validate the image size
                        // if (image.width < this.webImageSizeWidth) {
                        //     alert('Invalid Image  Size. Please Upload Image of Width: 1520px')
                        //     this.$data.previewDesktopURL = null;
                        //     this.showDesktopUploadButton = true;
                        // } else if (image.height < this.webImageSizeHeight) {
                        //     alert('Invalid Image  Size. Please Upload Image of Height: 380px')
                        //     this.$data.previewDesktopURL = null;
                        //     this.showDesktopUploadButton = true;
                        // } else if(image.width == this.webImageSizeWidth && image.height == this.webImageSizeHeight) {
                        this.$data.previewDesktopURL = event.target.result;
                        // } else {
                        //     alert('Invalid Image  Size. Please Upload Image of Width: 1520px  X  Height: 380px')
                        //     this.$data.previewDesktopURL = null;
                        //     this.showDesktopUploadButton = true;
                        // }
                    }
                };
                reader.readAsDataURL(file);
            } else {
                this.$data.previewDesktopURL = null;
            }
            // do something
        },
        onMobileBannerChanged(e) {
            this.selectedFile = e.target.files[0];
            const file = e.target.files[0];
            this.mobileBannerImageName = file.name;
            this.removeMobileBannerName = file.name;
            this.contentMobileType = file.type;
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const image = new Image();
                    image.src = event.target.result;

                    image.onload = () => {
                        // Validate the image size
                        // if (image.width < this.mobileImageSizeWidth) {
                        //     alert('Invalid Image  Size. Please Upload Image of Width: 335px')
                        //     this.previewMobileURL = null;
                        //     this.showMobileUploadButton = true;
                        // } else if (image.height < this.mobileImageSizeHeight) {
                        //     alert('Invalid Image  Size. Please Upload Image of Height: 188px')
                        //     this.previewMobileURL = null;
                        //     this.showMobileUploadButton = true;
                        // } else if(image.width == this.mobileImageSizeWidth && image.height == this.mobileImageSizeHeight) {
                        this.previewMobileURL = event.target.result;
                        // } else {
                        //     alert('Invalid Image  Size. Please Upload Image of Width and Height: 335px and 188px');
                        //     this.previewMobileURL = null;
                        //     this.showMobileUploadButton = true;
                        // }
                    }
                };
                reader.readAsDataURL(file);
            } else {
                this.previewMobileURL = null;
            }            
            // do something
        },
        getRouteNames() {
            return router.options.routes
            .filter(route => route.visibility === true)
            .map(route => route.name);
        },
        discardAddBannerDialog() {
            this.displayDiscardDialog = true;
        },
    },
    async mounted() {
        this.multipleAdds = this.cloudFront + this.multipleAdds;
        this.desktopImage = this.cloudFront + this.desktopImage;
        this.mobileImage = this.cloudFront + this.mobileImage;
        this.uploadBannerIcon = this.cloudFront + this.uploadBannerIcon;
        this.editIcon = this.cloudFront + this.editIcon;
        this.deleteIcon = this.cloudFront + this.deleteIcon;
        this.routeNames = this.getRouteNames();
        if(this.$props.editBannerFlagProps) {
            this.editFlag = true;
            let bannerDatum = this.editBannerDetails;
            this.shortID = bannerDatum.short_id;
            this.bannerType = bannerDatum.bannerType;
            this.bannerName = bannerDatum.bannerName;
            let responseDataList = bannerDatum.responseDataList;
            if(bannerDatum.bannerType == 'Single') {
                for (let i of responseDataList) {
                    this.activeStatus = i.activeStatus; // === 'ACTIVE' ? true : false;
                    this.bannerStartDate = i.bannerStartDate;
                    this.bannerExpireDate = i.bannerExpireDate;
                    this.bannerPriority = i.bannerPriority;
                    this.jumpLink = i.jumpLink;
                    this.desktopBannerImageName = i.desktopBannerImageName;
                    this.mobileBannerImageName = i.mobileBannerImageName;
                    this.desktopBannerImageURL = i.desktopBannerImageURL;
                    this.$data.previewDesktopURL = i.desktopBannerImageURL;
                    if (this.$data.previewDesktopURL) {
                        this.showDesktopUploadButton = false;
                    }
                    this.mobileBannerImageURL = i.mobileBannerImageURL;
                    this.$data.previewMobileURL = i.mobileBannerImageURL;
                    if (this.$data.previewMobileURL) {
                        this.showMobileUploadButton = false;
                    }
                }
            } else {
                this.bannerData = bannerDatum.responseDataList;
                this.collapsibleBannerFlag = true;
            }
        }
    },
  };
</script>

<style scoped>
.showCollasibleBanner {
    height: 64px;
    width: 100%;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}
.custom-append-icon .v-input__icon--append {
  background-image: url("https://s3iconimages.mymedicine.com.mm/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.bannerBorder {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}
.mtba {
    margin-top: auto;
    margin-bottom: auto;
}
.colorBlue {
    color: #48ACEF;
}
.discardBorder {
    border: 1px solid #828282; border-radius: 6px;
}
.borderBlue {
    border: 1px solid #48ACEF; border-radius: 6px;
}
.uploadBannerButtonStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    width: 117px;
    height: 36px;
    color: #FFFFFF;
    background: #48ACEF;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.saveDiscardButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 16px;
    width: 176px;
    height: 48px;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.discardButtonFont {
    color: #828282;
}
.saveButtonFont {
    color: #FFFFFF;

}
.btnClass {
  background: transparent !important;
  color: rgb(228, 26, 26) !important;
  margin-right: -8%;
  font-size: 12pt !important;
}
.removeButtonStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    width: 112px;
    height: 36px;
    background: #EB5757;
    color: #FFFFFF;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.mtb20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.height80 {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 54px;
}
.height200 {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 196.38px;
}
.collapsible {
    cursor: pointer;
    padding: 18px;
    background-color: #f1f1f1;
    border: none;
    text-align: left;
    outline: none;
    width: 100%;
}
.content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #ffffff;
}
.v-sheet.v-card:not(.v-sheet--outlined) { 
    box-shadow: none !important;
}
.enabledDiv {
    padding: 10px 16px;
    gap: 10px;
    width: 200px;
    height: 42px;
    background: #48ACEF;
    border-radius: 4px;
    cursor: pointer;
    flex: none;
    order: 1;
    flex-grow: 0;
}
</style>